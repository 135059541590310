<template lang="">
    <div class="record-of-test">
        <Result :isCMS="true" />
    </div>
</template>

<script>
import Result from '../../components/Web/Result';

export default {
    components: {
        Result,
    },
};
</script>

<style scoped lang="scss">
.record-of-test {
    height: 100%;
    background-color: #f9f9f9;
    padding: 20px;
}
</style>
